<template>
	<div class="header d-flex flex-nowrap justify-start align-start width-100 pl-0">
		<v-btn @click="click" icon small :ripple="false" plain color="var(--high) !important">
			<v-icon size="14" class="mt-4" :class="{ rotated: value }">
				far
				{{ value ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</v-btn>
		<div class="width-100">
			<div class="width-100 pointer" @click="click">
				<div class="header-title fw-semi fs-regular d-flex align-center mt-2" :class="{ disabled }" style="width: 100%; height: 20px">
					<div class="flex-grow-1">
						{{ title }}
					</div>
					<div class="ml-auto">{{ total }}</div>
				</div>

				<div class="kpibar d-flex justify-start">
					<slot />
				</div>
			</div>
			<v-expand-transition>
				<slot name="expand" v-bind="{ value }" />
			</v-expand-transition>
		</div>
	</div>
</template>

<script>
import _debounce from 'debounce';
import { adjustNotificationsHeight } from '@/api/common';

export default {
	props: {
		value: {},

		disabled: {
			type: Boolean,
			default: false
		},

		title: {
			type: String
		},

		total: {
			type: Number
		}
	},
	data() {
		return {};
	},
	mounted() {},
	updated() {},
	methods: {
		click() {
			this.$emit('input', !this.value);
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.header {
	color: var(--high);

	& .icon {
		transform-origin: center center;
	}

	& .rotated {
		transform: rotate(-180deg);
	}

	& .header-title {
		transition: color 0.2s ease-in !important;
	}

	& .disabled {
		color: var(--moderate);
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
