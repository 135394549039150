import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import constants from '@/utils/constants';

/** Styles */
import areaNotificationStylePolygon from '@/components/map/sections/map/styles/areaNotificationStylePolygon';

export default {
	data() {
		return {
			selectedAreaId: null
		};
	},
	computed: {
		layers: {
			get() {
				return this.$store.getters.layers;
			},
			set(value) {
				this.$store.commit('setLayers', value);
			}
		}
	},
	methods: {
		centerAreaMap(areaId) {
			let areas = this.$store.getters.getAreas;
			//let userProps = this.userProperties;
			let selectedArea = areas.find((area) => area.pmareaid == areaId);
			//let polygon = [];
			// TODO: use area points array [lat, lon] to center on area. example:
			/* {
				name: 'Duisport area', // name to show in legend
				color: '#3361DB', // line highlight color
				polygon: [
					[51.4497, 6.7209],
					[51.444, 6.7582],
					[51.4495, 6.7921],
					[51.4318, 6.8045],
					[51.4164, 6.7919]
				]
			}; */
			if (selectedArea && selectedArea.areapoints) {
				let areaDefinition = {
					name: selectedArea.name,
					color: selectedArea.color,
					polygon: JSON.parse(selectedArea.areapoints)
				};
				//console.log('NEW Structure: Area points ->', areaDefinition);
				this.$puiNotify.info(this.$t('map.areaSelected'), areaDefinition.name);
				const areaNotificationLayer = ol2map.getLayerById(constants.AREA_NOTIFICATION);

				if (areaNotificationLayer) {
					const sourceAreaNotification = areaNotificationLayer.getSource(); 
					if (sourceAreaNotification && typeof sourceAreaNotification.addFeature === 'function') {
						// Invertir las coordenadas del polígono (de [lat, lon] a [lon, lat]) y crear la geometría
						const invertedPolygon = areaDefinition.polygon.map(coord => coord.reverse());
						let polygonGeometry = ol2map.createPolygonGeometry(invertedPolygon);
						polygonGeometry.transform('EPSG:4326', 'EPSG:3857');
						// Buscar si ya existe un polígono con las mismas propiedades
						let existingFeature = null;
						sourceAreaNotification.getFeatures().forEach(feature => {
							if (feature.get('name') === areaDefinition.name 
								&& feature.getGeometry().getCoordinates().toString() === polygonGeometry.getCoordinates().toString()
								&& feature.get('color') === areaDefinition.color)
								{
								existingFeature = feature;
							}
						});

						if (existingFeature) {
							sourceAreaNotification.removeFeature(existingFeature);
						} else {
							// Crear un feature con la geometría y las propiedades
							/*let feature = new Feature({
								geometry: polygonGeometry,
								name: areaDefinition.name,
								color: areaDefinition.color
							});*/
							let feature = ol2map.createMapFeature(areaDefinition, polygonGeometry)
						
							// Aplicar estilo al feature basado en el color y agregarlo a la fuente de la capa
							feature.setStyle(areaNotificationStylePolygon);
							sourceAreaNotification.addFeature(feature);
		
							const newTemporalLayer = {
								active: true,
								datasourcetypeicon: '',
								identifier: areaDefinition.name,
								model: constants.AREA_NOTIFICATION,
								title: areaDefinition.name,
								color: areaDefinition.color
							};
							this.$set(this.layers, areaDefinition.name, newTemporalLayer);
							ol2map.zoomToTransformedExtent(ol2map.boundingExtent(invertedPolygon), 'EPSG:4326', 'EPSG:3857');
						}
					} else {
						console.error("La fuente de 'areaNotificationLayer' no es válida o no tiene 'addFeature'");
					}
				} else {
					console.error("No se encontró la capa 'areaNotificationLayer'");
				}
				return areaDefinition;
			} else {
				this.$puiNotify.warning(this.$t('map.areaNotFound'), this.$t('status.warning'));
			}
		}
	}
};
