import { Style, Fill, Stroke, Text } from 'ol/style';

const areaNotificationStyleFunction = function (feature) {
    const areaColor = feature.get('color') || '#FF0000'; 
    const areaName = feature.get('name') || '';

    return [
        new Style({
            fill: new Fill({
                color: areaColor + '66'
            }),
            stroke: new Stroke({
                color: areaColor,
                width: 2
            }),
            text: new Text({
                text: areaName,
                font: 'bold 14px Montserrat, sans-serif',
                fill: new Fill({ color: '#000' }),
                stroke: new Stroke({ color: '#fff', width: 2 }),
                offsetY: -15
            })
        })
    ];
};

export default areaNotificationStyleFunction;
