<template>
	<div class="header d-flex flex-nowrap justify-start align-start width-100 pl-0">
		<v-btn @click="click" icon small :ripple="false" plain color="var(--high) !important">
			<v-icon size="14" class="mt-4" :class="{ rotated: value }">
				far
				{{ value ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</v-btn>
		<div class="width-100">
			<!-- <v-hover v-slot="{ hover }"> -->
			<div
				class="width-100 pa-1 rounded"
				style="background-color: var(--fondo-1)"
				:ripple="false"
				@click="toggleSelection(areaId)"
				:title="$t('panels.notifications.clickToCenterArea')"
				:class="{ selected: isSelected }"
				:style="isSelected ? { border: `2px solid ${selectedColor} !important`, backgroundColor: selectedColor } : {}"
				flat
			>
				<div class="header-title fs-regular d-flex align-center mt-2 color-black" :class="{ disabled }" style="width: 100%; height: 20px">
					<div class="flex-grow-1">
						{{ title }}
					</div>
					<div class="ml-auto">{{ total }}</div>
				</div>

				<div class="kpibar d-flex justify-start" style="border-radius: 2px">
					<slot />
				</div>
			</div>
			<!-- </v-hover> -->
			<v-expand-transition>
				<slot name="expand" v-bind="{ value }" />
			</v-expand-transition>
		</div>
	</div>
</template>

<script>
import { adjustNotificationsHeight } from '@/api/common';
import _debounce from 'debounce';
import AreaMixin from '@/mixins/AreaMixin';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import constants from '@/utils/constants';
import Vue from 'vue';

export default {
	mixins: [AreaMixin],
	props: {
		value: {},
		disabled: {
			type: Boolean,
			default: false
		},
		title: {
			type: String
		},
		areaId: {
			type: String
		},
		total: {
			type: Number
		}
	},
	data() {
		return {
			selectedArea: null,
			selectedColor: 'blue'
		};
	},
	created() {
		this.$root.$on('hide-all-selected-areas', this.hideAllSelectedAreas);
	},
	beforeDestroy() {
		this.$root.$off('hide-all-selected-areas');
	},
	methods: {
		click() {
			this.$emit('input', !this.value);
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
		},
		toggleSelection(areaId) {
			if (this.selectedArea === areaId) {
				let areas = this.$store.getters.getAreas;
				let selectedArea = areas.find((area) => area.pmareaid == areaId);
				Vue.delete(this.layers, selectedArea.name);
				this.selectedArea = null;
				this.selectedColor = 'transparent';
			} else {
				this.selectedArea = areaId;
				let areas = this.$store.getters.getAreas;
				let selectedArea = areas.find((area) => area.pmareaid == areaId);
				if (selectedArea && selectedArea.areapoints) {
					this.selectedColor = selectedArea.color || 'blue';
				}
			}
			this.centerAreaMap(areaId);
		},
		hideAllSelectedAreas() {
			const areas = this.$store.getters.getAreas;
			areas.forEach((area) => {
				Vue.delete(this.layers, area.name);
			});
			this.selectedArea = null;
			this.selectedColor = 'transparent';
			const areaNotificationLayer = ol2map.getLayerById(constants.AREA_NOTIFICATION);
			if (areaNotificationLayer && areaNotificationLayer.getSource) {
				const source = areaNotificationLayer.getSource();
				if (source && source.clear) {
					source.clear();
				}
			}
		}
	},
	computed: {
		isSelected() {
			return this.selectedArea === this.areaId;
		},
		layers: {
			get() {
				return this.$store.getters.layers;
			},
			set(value) {
				this.$store.commit('setLayers', value);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.v-card.selected {
	border-radius: 5px;
}
.card-spacing {
	margin-bottom: 5px;
}
.header {
	color: var(--high);

	& .icon {
		transform-origin: center center;
	}

	& .rotated {
		transform: rotate(-180deg);
	}

	& .header-title {
		transition: color 0.2s ease-in !important;
		cursor: pointer;
	}

	& .disabled {
		color: var(--moderate);
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
