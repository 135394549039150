<template>
	<div>
		<v-list v-for="(areaType, i) in areasByType" :key="i" flat dense style="background: transparent" class="pt-0">
			<v-list-group append-icon="" no-action class="pa-0" @click="opened(areaType)" :ripple="false">
				<template #activator>
					<v-list-item-title class="high fw-bold fs-regular ml-n4 color-black" style="line-height: 1.125rem">{{
						areaType.pmareatypename
					}}</v-list-item-title>
				</template>
				<template #prependIcon>
					<v-icon size="16" color="black" :class="{ rotated: isOpened[areaType.pmareatypeid] }">
						far
						{{ isOpened[areaType.pmareatypeid] ? 'fa-minus' : 'fa-plus' }}
					</v-icon>
				</template>
				<kpi-panel :items="items_byAreaType(areaType.pmareatypeid)" :total-viewport="totalViewport_byArea" class="">
					<template slot-scope="{ item: { id: itemId, total: itemTotal, bars }, percents }">
						<component
							:is="bars ? 'kpi-expanded-header-area' : 'kpi-header'"
							v-bind="{
								total: itemTotal,
								title: titles_byArea[itemId],
								areaId: itemId.split('_')[1],
								disabled: isDisabled_byArea[itemId],
								showClose: closeButtons_byArea[itemId]
							}"
							v-on:click:close="clearFilter_byArea"
							v-model="expanded[itemId]"
						>
							<template v-if="!bars" #default>
								<kpi-bar-item
									:value="itemTotal"
									@click="() => filterMethod_byArea[itemId]()"
									:colorClass="colors_byArea[itemId]"
									:disabled="isDisabled_byArea[itemId]"
									:tip-text="titles_byArea[itemId]"
									:percent="percents[itemId]"
								/>
							</template>

							<template v-else #default>
								<kpi-bar-item
									v-for="{ id: barId, total: barTotal } in bars"
									:key="barId"
									:value="barTotal"
									@click="() => filterMethod_byArea[barId]()"
									:colorClass="colors_byArea[barId]"
									:disabled="isDisabled_byArea[barId]"
									:tip-text="titles_byArea[barId]"
									:percent="percents[barId]"
								/>
							</template>

							<template #expand>
								<div v-if="expanded[itemId]" class="mt-2">
									<kpi-detail-item
										v-for="{ id: barId, total: barTotal } in bars"
										:key="barId"
										:total="barTotal"
										@click="() => filterMethod_byArea[barId]()"
										:colorClass="colors_byArea[barId]"
										:disabled="isDisabled_byArea[barId]"
										:title="titles_byArea[barId]"
										:show-exclamation="exclamations_byArea[barId]"
									/>
								</div>
							</template>
						</component>
					</template>
				</kpi-panel>
			</v-list-group>
		</v-list>
	</div>
</template>

<script>
import KpiPanel from '../kpis-common/KpiPanel';
import KpiHeader from '../kpis-common/KpiHeader';
import KpiExpandedHeaderArea from '../kpis-common/KpiExpandedHeaderArea';
import KpiBarItem from '../kpis-common/KpiBarItem';
import KpiDetailItem from '../kpis-common/KpiDetailItem';

import NotificationsKpiMixin from '@/mixins/NotificationsKpiMixin';
//import debounce from 'debounce';
import _debounce from 'debounce';
import { adjustNotificationsHeight } from '@/api/common';

export default {
	name: 'NotificationsKpiPanelArea',

	mixins: [NotificationsKpiMixin],

	components: {
		KpiPanel,
		KpiHeader,
		KpiExpandedHeaderArea,
		KpiBarItem,
		KpiDetailItem
	},
	data() {
		return {
			isOpened: {}
		};
	},
	computed: {
		areasByType() {
			return this.$store.getters.getAreas.reduce((r, { pmareatypename, pmareatypeid, ...rest }) => {
				if (!r[pmareatypename]) r[pmareatypename] = { pmareatypename, pmareatypeid, areas: [rest] };
				else r[pmareatypename].areas.push(rest);
				return r;
			}, {});
		}
	},
	mounted() {
		this.$store.getters.getAreas.forEach((areaType) => {
			this.isOpened[areaType.pmareatypeid] = false;
		});
	},

	methods: {
		opened(areaType) {
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
			this.isOpened[areaType.pmareatypeid] = !this.isOpened[areaType.pmareatypeid];
		}
	}
};
</script>
<style lang="postcss">
.kpipanel {
	& .v-list-group__header {
		padding: 0px !important;
	}
}
.rotated {
	transform: rotate(-180deg);
}
</style>
