<template>
	<div class="notificationscardicon d-flex justify-center">
		<svg-icon :icon="getIcon" :width="24"></svg-icon>
	</div>
</template>

<script>
import SvgIcon from '@/components/map/icon/SvgIcon';

export default {
	name: 'notificationscardicon',

	props: {
		feature: {
			type: Object,
			required: true
		}
	},

	components: {
		SvgIcon
	},

	computed: {
		getIcon() {
			//console.warn(this.feature);
			// Extract the 'status' property from the 'feature' object, using destructuring.
			const { feature: { icon_route } = {} } = this;

			// Check if 'icon_status' is falsy, if so, return the default icon.
			/* if (!icon_status) {
				return 'defaulticon';
			} */
			// Return the 'status' if it's truthy.
			return icon_route;
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.notificationscardicon {
	width: 24px;
}
</style>
